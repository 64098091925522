import { portalService } from './service'
import { PersonalInfoForm } from 'src/context/type'

const financeOverviewApi = () => {
  return portalService({
    url: '/finance/balance',
    method: 'get',
  })
}
const setMinPaymentApi = (data: any) => {
  return portalService({
    url: '/finance/payment/min-amount',
    method: 'patch',
    data,
  })
}

const financeProfileDataApi = () => {
  return portalService({
    url: '/finance/profile',
    method: 'get',
  })
}

const financePaymentHistoryApi = (params: {
  page: number
  page_size: number
}) => {
  return portalService({
    url: '/finance/history',
    method: 'get',
    params,
  })
}

const financePersonalInfoApi = (params?: PersonalInfoForm) => {
  return portalService({
    url: '/finance/personal/info',
    method: 'post',
    params,
  })
}
const financeProfileCompletionApi = (params?: any) => {
  return portalService({
    url: '/finance/completion',
    method: 'post',
    params,
  })
}

export {
  financeOverviewApi,
  financeProfileDataApi,
  financePaymentHistoryApi,
  financePersonalInfoApi,
  financeProfileCompletionApi,
  setMinPaymentApi,
}
