const SVG = (props: any) => {
  const width = props.width || '22'
  const height = props.height || '22'
  const color = props.color || '#7487AA'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.58248 3.24375C6.96912 3.24375 5.66123 4.55164 5.66123 6.165C5.66123 7.77835 6.96912 9.08625 8.58248 9.08625C10.1959 9.08625 11.5037 7.77835 11.5037 6.165C11.5037 4.55164 10.1959 3.24375 8.58248 3.24375ZM4.25098 6.165C4.25098 3.77278 6.19026 1.8335 8.58248 1.8335C10.9747 1.8335 12.914 3.77278 12.914 6.165C12.914 8.55722 10.9747 10.4965 8.58248 10.4965C6.19026 10.4965 4.25098 8.55722 4.25098 6.165Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79407 14.1227C4.83328 14.1227 3.24375 15.7122 3.24375 17.673C3.24375 17.7706 3.26058 17.8379 3.27748 17.8762C3.2911 17.9069 3.30113 17.9127 3.30558 17.9153C3.78923 18.1996 5.18055 18.7564 8.58258 18.7564C11.9846 18.7564 13.3755 18.1998 13.8592 17.9156C13.8636 17.9129 13.8741 17.9069 13.8877 17.8762C13.9046 17.8379 13.9214 17.7706 13.9214 17.673C13.9214 15.7122 12.3319 14.1227 10.3711 14.1227H6.79407ZM1.8335 17.673C1.8335 14.9334 4.05441 12.7124 6.79407 12.7124H10.3711C13.1107 12.7124 15.3317 14.9334 15.3317 17.673C15.3317 18.1448 15.1722 18.7796 14.5737 19.1314C13.7851 19.5949 12.1083 20.1666 8.58258 20.1666C5.05685 20.1666 3.38011 19.5949 2.59142 19.1314C1.99298 18.7796 1.8335 18.1448 1.8335 17.673Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2481 3.56332C14.3497 3.18738 14.7368 2.96499 15.1128 3.06659C16.6682 3.48697 17.7493 5.02291 17.7493 6.76927C17.7493 8.61454 16.4845 10.2783 14.7107 10.4914C14.3241 10.5378 13.973 10.262 13.9265 9.87539C13.88 9.48874 14.1558 9.1376 14.5425 9.09116C15.4884 8.97753 16.3391 8.02767 16.3391 6.76927C16.3391 5.57533 15.607 4.66102 14.7448 4.42801C14.3689 4.3264 14.1465 3.93927 14.2481 3.56332Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.352 13.2827C16.4266 12.9004 16.7969 12.651 17.1791 12.7256C18.9144 13.064 20.1668 14.5842 20.1668 16.3523V16.8424C20.1668 17.2764 20.0367 17.8945 19.4705 18.2563C19.0894 18.5 18.4983 18.7596 17.5869 18.9439C17.2052 19.0212 16.8332 18.7743 16.7559 18.3926C16.6787 18.0109 16.9255 17.6389 17.3072 17.5617C18.0848 17.4044 18.505 17.1998 18.711 17.0681L18.7126 17.0665C18.7142 17.0645 18.7197 17.0571 18.7265 17.0405C18.7415 17.004 18.7565 16.9386 18.7565 16.8424V16.3523C18.7565 15.2591 17.9821 14.319 16.9092 14.1098C16.5269 14.0351 16.2775 13.6649 16.352 13.2827Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
