import { portalService } from './service'

export const getEchartApi = (data?: any) => {
  return portalService({
    url: '/chart',
    method: 'post',
    data,
    timeout: 60 * 1000,
  })
}
export const getInstallsApi = (data?: any) => {
  return portalService({
    url: '/metric/installs/search',
    method: 'post',
    data,
    timeout: 60 * 1000,
  })
}

export const getEchartGeoApi = (data?: any) => {
  return portalService({
    url: '/chart/geo',
    method: 'post',
    data,
    timeout: 60 * 1000,
  })
}
export const getEchartApiV2 = (data?: any) => {
  return portalService({
    url: '/chart/v2',
    method: 'post',
    data,
    timeout: 60 * 1000,
  })
}
export const getArpuCardApi = (data?: any) => {
  return portalService({
    url: '/chart/card/arpu',
    method: 'post',
    data,
    timeout: 120 * 1000,
  })
}
export const getStickinessCardApi = (data?: any) => {
  return portalService({
    url: '/chart/card/stickiness',
    method: 'post',
    data,
    timeout: 120 * 1000,
  })
}
export const getEchartTopGamesApi = (data?: any) => {
  return portalService({
    url: '/chart/top_games',
    method: 'post',
    data,
  })
}
export const getRetentionsApi = (
  app_key: string,
  params: {
    from_date: string
  }
) => {
  return portalService({
    url: `/chart/retention/${app_key}/`,
    method: 'get',
    params,
  })
}
export const notificationListApi = (params: {
  page: number
  page_size: number
}) => {
  return portalService({
    url: '/notification',
    method: 'get',
    params,
  })
}
export const notificationActionApi = (id: number, data: any) => {
  return portalService({
    url: `/notification/${id}/set_action`,
    method: 'patch',
    data,
  })
}
export const markAsReadApi = (id: string) => {
  return portalService({
    url: `/notification/${id}/mark_as_read`,
    method: 'patch',
  })
}
export const countriesListApi = () => {
  return portalService({
    url: '/chart/countries',
    method: 'get',
  })
}
export const addChartConfigApi = (data?: any) => {
  return portalService({
    url: '/chart/chart_config',
    method: 'post',
    data,
  })
}
export const putChartConfigApi = (data?: any) => {
  return portalService({
    url: '/chart/chart_config',
    method: 'put',
    data,
  })
}
export const deleteChartConfigApi = (index?: number) => {
  return portalService({
    url: `/chart/chart_config/${index}`,
    method: 'delete',
  })
}
export const setDefaultConfigApi = (index?: number) => {
  return portalService({
    url: `/chart/chart_config/set_default_config`,
    method: 'patch',
    data: { default_selected: index },
  })
}
export const getFlashNotificationApi = () => {
  return portalService({
    url: '/notification/flash_notification',
    method: 'get',
  })
}
export const setFlashNotificationApi = (flash_notification_id?: number) => {
  return portalService({
    url: `/notification/flash_notification/${flash_notification_id}/mark_as_read`,
    method: 'patch',
  })
}
export const updateMonetizationApi = (app_key: string, data: any) => {
  return portalService({
    url: `/game/${app_key}/monetization`,
    method: 'patch',
    data,
  })
}
export const recordMonetizationApi = (app_key: string) => {
  return portalService({
    url: `/game/${app_key}/action_record`,
    method: 'patch',
    data: {
      action: 'monetization',
    },
  })
}
export const getPerformanceScoringApi = () => {
  return portalService({
    url: '/scoring/scoring',
    method: 'get',
  })
}
