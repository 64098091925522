import { portalService } from './service'
import { PersonaInfoRequest, PersonaPasswordRequest } from 'src/api'

/**
 * 修改个人信息
 * @param params
 * @returns
 */
export const settingPersonalInfoApi = (params: PersonaInfoRequest) => {
  return portalService({
    url: '/user/me',
    method: 'patch',
    data: params,
  })
}

/**
 * 修改密码
 * @param params
 * @returns
 */
export const settingPersonalPasswordApi = (params: PersonaPasswordRequest) => {
  return portalService({
    url: '/user/me/password',
    method: 'patch',
    data: params,
  })
}

/**
 * 上传头像
 * @param params
 * @returns
 */
export const settingUploadAvatarApi = (params: FormData) => {
  return portalService.post('/avatar/upload', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

/**
 * 获取修改邮箱-验证码
 * @param params
 * @returns
 */
export const settingEmailCodeApi = (params: { email: string }) => {
  return portalService.post('/user/email_change_code', params)
}

/**
 * 修改邮箱
 * @param params
 * @returns
 */
export const settingEmailChangeApi = (params: {
  email: string
  verify_code: string
}) => {
  return portalService.patch('/user/me/email', params)
}

/**
 * 打开/关闭2FA
 * @param params
 * @returns
 */
export const change2FAApi = (params: { passwd: string; two_fa: boolean }) => {
  return portalService.patch('/user/me/2FA/status', params)
}
