const SVG = (props: any) => {
  const width = props.width || '64'
  const height = props.height || '64'
  const color = props.color || '#364259'
  return (
    <svg
      width={width}
      height={height}
      style={props.style}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.2764 0H18.6436C15.3554 0.00372869 12.6908 2.66835 12.6875 5.95659V50.0437C12.6908 53.332 15.3554 55.9966 18.6436 55.9998H37.2764C40.5646 55.9966 43.2292 53.332 43.233 50.0437V5.95659C43.2292 2.66835 40.5646 0.00372869 37.2764 0V0ZM40.6872 50.0437C40.6853 51.9267 39.1594 53.4527 37.2764 53.4545H18.6436C16.7606 53.4527 15.2347 51.9267 15.2328 50.0437V5.95659C15.2347 4.07313 16.7606 2.54716 18.6436 2.5453H37.2764C39.1594 2.54716 40.6853 4.07313 40.6872 5.95659V50.0437Z"
        fill={color}
      />
      <path
        d="M31.7784 5.09082H24.142C23.4392 5.09082 22.8691 5.66038 22.8691 6.36324C22.8691 7.0661 23.4392 7.63612 24.142 7.63612H31.7784C32.4812 7.63612 33.0508 7.0661 33.0508 6.36324C33.0508 5.66038 32.4812 5.09082 31.7784 5.09082Z"
        fill={color}
      />
      <path
        d="M30.5051 48.3637C30.5051 49.7698 29.3655 50.9094 27.9598 50.9094C26.5536 50.9094 25.4141 49.7698 25.4141 48.3637C25.4141 46.9579 26.5536 45.8184 27.9598 45.8184C29.3655 45.8184 30.5051 46.9579 30.5051 48.3637Z"
        fill={color}
      />
    </svg>
  )
}

export default SVG
