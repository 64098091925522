import { ssoService } from './service'

export type LoginBody = {
  email: string
  password: string
  device_id?: string
}
export type VerifyBody = {
  email: string
  code: string
  device_id?: string
}
export type RetryBody = {
  email: string
}

export const loginApi = (loginBody: LoginBody) => {
  return ssoService({
    url: '/user/login',
    method: 'post',
    data: loginBody,
  })
}
export const twoFaVerifyApi = (verifyBody: VerifyBody) => {
  return ssoService({
    url: '/user/2fa_verify',
    method: 'post',
    data: verifyBody,
  })
}
export const twoFaRetryApi = (retryBody: RetryBody) => {
  return ssoService({
    url: '/user/2fa_retry',
    method: 'post',
    data: retryBody,
  })
}
